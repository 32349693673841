@import './styles/variables.scss';

html,
body {
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

body {
  background-color: #f2f2f2;
  color: $color-white;
  font-family: $font-family;
  overflow: hidden;
}

#root {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: auto;
}

.app {
  height: 100%;
}

// Helpers

.text-right {
  text-align: right;
}
