.pull-down-wrapper {
  height: 70vh;
  z-index: -10;
}

.container-spinner {
  text-align: center;
}

.spinner {
  display: inline-block;
  font-size: 30px;
  height: 1em;
  margin: 25px auto 16px auto;
  position: relative;
  width: 1em;
}

.spinner .spinner-blade {
  animation: spinner-first 2s, spinner-second 2s linear infinite;
  background-color: transparent;
  border-radius: 0.0555em;
  bottom: 0;
  height: 0.2777em;
  left: 0.4629em;
  position: absolute;
  transform-origin: center -0.2222em;
  width: 0.074em;
}
.spinner .spinner-blade:nth-child(1) {
  -ms-transform: rotate(0deg);
  animation-delay: 0s;
  transform: rotate(0deg);
}
.spinner .spinner-blade:nth-child(2) {
  -ms-transform: rotate(30deg);
  animation-delay: 0.083s;
  transform: rotate(30deg);
}
.spinner .spinner-blade:nth-child(3) {
  -ms-transform: rotate(60deg);
  animation-delay: 0.166s;
  transform: rotate(60deg);
}
.spinner .spinner-blade:nth-child(4) {
  -ms-transform: rotate(90deg);
  animation-delay: 0.249s;
  transform: rotate(90deg);
}
.spinner .spinner-blade:nth-child(5) {
  -ms-transform: rotate(120deg);
  animation-delay: 0.332s;
  transform: rotate(120deg);
}
.spinner .spinner-blade:nth-child(6) {
  animation-delay: 0.415s;
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.spinner .spinner-blade:nth-child(7) {
  animation-delay: 0.498s;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.spinner .spinner-blade:nth-child(8) {
  animation-delay: 0.581s;
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.spinner .spinner-blade:nth-child(9) {
  animation-delay: 0.664s;
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.spinner .spinner-blade:nth-child(10) {
  animation-delay: 0.747s;
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.spinner .spinner-blade:nth-child(11) {
  animation-delay: 0.83s;
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.spinner .spinner-blade:nth-child(12) {
  animation-delay: 0.913s;
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-second {
  50% {
    background-color: #69717d;
  }
  150% {
    background-color: transparent;
  }
}

@keyframes spinner-first {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
