$gradient: linear-gradient(
  33deg,
  rgba(235, 73, 78, 1) 0%,
  rgba(239, 94, 56, 1) 100%
);

$font-family: 'Roboto Condensed', sans-serif;
$font-weight-bold: 700;
$font-size: 14px;
$font-spacing: 0.6px;

$font-header-1-size: 32px;
$font-header-2-size: 20px;
$font-header-3-size: 16px;

$color-white: #fff;
$color-black: #252525;
$color-secondary-grey: #919191;
$color-purple: #892886;
$color-red: #e94057;
$color-green: #05c500;

$color-red-style-2: #e94057;
$secondary-blue: #75a3db;
$secondary-candy: #ad7bff;
$secondary-apple: #6bcf8d;
$secondary-sand: #f6a634;
$color-orange-style: #f27123;
$secondary-yellow: #dda400;
$color-green-style: #05c500;
$secondary-peach: #ff7b7b;
$secondary-aqua: #4ecdb3;
$secondary-sky: #3089ba;
$secondary-seal: #43839d;
$secondary-grey: #919191;
$secondary-light-grey: #e4e4e4;
$secondary-tarro: #5c4fbb;
$warm-purple: #892886;
